const ChurnReasons = [
  {
    value: 'Falha Interna',
    data: [
      'Cliente fora de ICP',
      'Cliente sem integração',
      'Falha no lançamento de informações internas',
      'Medo de baixar margem com clube',
      'Não entregamos valor',
      'Não envolvemos proprietário/decisor no projeto',
      'Produto vendido não atende o cliente.',
      'Sem cobertura de carteira',
      'Sem lider de projeto',
      'Quebra de Expectativa'
    ]
  },
  {
    value: 'Fator Externo',
    data: [
      'Arrependimento de compra',
      'Cliente entrou para uma RAF Cliente MF',
      'Fechamento de loja',
      'Inadimplência',
      'Não configurou o TOKEN',
      'Oscilação internet constante',
      'Saúde financeira',
      'Banimento WhatsApp',
      'Reestruturação interna',
      'Cliente saiu da RAF',
      'Troca CNPJ, mesmo cliente',
      'Troca de proprietário não interessado',
      'Troca por concorrente sem custo ou menor valor',
      'Turn over',
      'A Rede não engajou seus associados',
      'Líder desinteressado/Parou interação'
    ]
  },
  {
    value: 'Produto',
    data: [
      'Bug no produto',
      'Falha de envio Canal SMS',
      'Falta de funcionalidade',
      'Incosistência de dados',
      'Intermitência WhatsApp',
      'Produto descontinuado',
      'Queda PDV'
    ]
  },
  {
    value: 'Falha na Integração',
    data: [
      'Envio dos dados ERP/PDV inconsistente',
      'Falha na confirmação de vendas durante consulta',
      'Falha operacional do cliente no PDV',
      'Intermitênica na Integração',
      'Oscilação internet constante'
    ]
  },
  {
    value: 'Concorrência',
    data: ['Cliente aderiu ao CRM da Rede/RAF', 'Cliente entrou para uma Rede/RAF com CRM', 'Preço', 'Produto', 'Troca ERP']
  },
  {
    value: 'Troca por outro produto interno',
    data: [
      'Troca por outro produto da Mercafacil de menor valor',
      'Baixo engajamento dos clientes no produto da Mercafacil',
      'Mudança de estratégia para outro produto da Mercafacil'
    ]
  },
  {
    value: 'Compliance',
    data: ['Sem contrato']
  }
]

module.exports = {
  ChurnReasons
}
